import { Model } from 'pinia-orm';
import { Attr, Str, Uid } from 'pinia-orm/decorators';

export default class AreaModel extends Model {
  static entity = 'areas'

  //static primaryKey = 'myId'    // set primary key other than 'id' (default)


  @Uid() declare id: number;
  @Str("") declare name: string;
  @Str("") declare land: string;
  @Attr({}) declare center_lat_lon: any;
  @Attr("") declare start_zoom_level: string;
  @Attr({}) declare geom: any;
  @Attr(null) declare image: any;
  @Attr(null) declare logo: any;
}
