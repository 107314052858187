import { createI18n } from 'vue-i18n';
// @ts-ignore
import filemessages from './lang';
//import * as myglobal from './lang/global.json'

//TODO get locale from storage / browser settings?
const getLocaleFromStorage = /*localStorage.getItem('locale') ||*/ 'de';

export const i18n = createI18n({
  globalInstall: true,
  locale: getLocaleFromStorage,
  fallbackLocale: 'en', // set fallback locale
  messages: filemessages,
  globalInjection: true,
  legacy: false, // switch the API mode, from Legacy API mode to Composition API mode
});