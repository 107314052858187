import { Model } from 'pinia-orm';
import { Attr, Num, Uid, Str, Bool } from 'pinia-orm/decorators';

export default class UserModel extends Model {
  static entity = 'users'

  //static primaryKey = 'myId'    // set primary key other than 'id' (default)

  @Uid() declare id: number;
  @Str("") declare username: string;
  @Str("")  declare email: string;
  @Num(null) declare role: number;
  @Attr(null) declare image: any;
  @Bool(false) declare blocked: boolean;
  @Bool(false) declare confirmed: boolean;
  @Attr([]) declare user_sim_permissions: any;
  @Attr([]) declare simulations: any;
}