<template>
  <ion-app>
    <div v-if="isBaseDataLoaded">
      <ion-router-outlet :key="$route.fullPath" />
    </div>
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { useAxiosRepo } from '@pinia-orm/axios';
import { useRepo } from 'pinia-orm';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useHttpRequests } from './utilities/useHttpRequests';
import AreaModel from './models/AreaModel';
import UserModel from './models/UserModel';
import SimulationModel from './models/SimulationModel';
import {isAuthenticated} from "@/api/AuthApi";

const { t } = useI18n({ useScope: 'global' })
const router = useRouter();
const { getSimulations, getLoggedInUser } = useHttpRequests();
let isBaseDataLoaded = ref(false);
const simulationRepo = useRepo(SimulationModel);
const userRepo = useRepo(UserModel);

const currentUrlParams = computed(() => router.currentRoute.value.params);

const currentUser = computed( () => {
    return userRepo.all().length ? userRepo.all()[0] : undefined;
});

async function getBaseData() {
  console.log("App get Base Data");
  // get logged in user
  if (isAuthenticated.value && userRepo.all().length === 0) {
      const myuser = await getLoggedInUser();
      userRepo.fresh([myuser]);
  }else if(!isAuthenticated.value && userRepo.all().length > 0){
      userRepo.flush();
  } 

  try {
    await useAxiosRepo(AreaModel as any).api().get('/areas?populate=*', { save: true, dataKey: "data" })
    if ( currentUrlParams.value.areaId ) {
      console.log("currentUrlParams.value.areaId", currentUrlParams.value.areaId);
      const simulations = await getSimulations( Number(currentUrlParams.value.areaId), currentUser.value?.id);
      simulationRepo.fresh(simulations);
    } 
  } catch (error) {
    console.log("AreaModel error: ", error);
  }
  
  isBaseDataLoaded.value = true
}

getBaseData()

</script>