

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';
// @ts-ignore
import { environment } from "../environments/environment";
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/float-elements.css';
import "@ionic/vue/css/ionic.bundle.css";
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';

/* Theme variables */

import { i18n } from "@/i18n";
import '@/styles/ionic_variables.css';
import '@/styles/utilities.scss';

import { createPiniaOrmAxios } from '@pinia-orm/axios';
import axios from 'axios';
import { createPinia } from 'pinia';
import { createORM } from 'pinia-orm';

import * as Sentry from "@sentry/vue";

const pinia = createPinia();
const piniaOrm = createORM();
const jwt = ""; // TODO: will be retrieved from localstorage, after the suer logged in

const strapiUrl = `${environment.BACKEND_URL}:${environment.BACKEND_PORT}`;
const strapiApiUrl = `${strapiUrl}/api`;

console.log("strapiApiUrl", strapiApiUrl);

piniaOrm().use(createPiniaOrmAxios({
  axios,
  headers: { 
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${jwt}`,
   },
  baseURL: strapiApiUrl
}))
pinia.use(piniaOrm)

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://6a666e30dada39ae7cdabd526f693061@o4507222280044544.ingest.de.sentry.io/4507222436806736",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ["localhost"],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE_NAME
});

app.use(IonicVue)
app.use(pinia)
app.use(i18n)
app.use(router)

router.isReady().then(() => {
    app.mount('#app');
  });
