import apiClient from "@/api/api";
import { ref } from "vue";

const storageCode = "token";

const saveJwt = (jwt: string) => {
    localStorage.setItem(storageCode, jwt);
    isAuthenticated.value = checkAuthenticated();
};

export const loadJwt = (): (string | null) => {
    return localStorage.getItem(storageCode);
};

export const deleteJwt = () => {
    localStorage.removeItem(storageCode);
    isAuthenticated.value = checkAuthenticated();
};

export const isAuthenticated = ref(checkAuthenticated());

function parseJwt (token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function isTokenExpired (token: string) {
    const tok = parseJwt(token);
    return tok.exp < (Date.now() / 1000);
}

export function checkAuthenticated() {
    const token = loadJwt();

    if (token && isTokenExpired(token)) {
        deleteJwt();
    }
    return !!loadJwt();
}

export async function loginAuth(email: string, pass: string) {
    const res = await apiClient.post("/auth/local?populate=*", {
        identifier: email,
        password: pass
    });
    const {jwt, user} = res.data;
    saveJwt(jwt);

    console.log(`User ${user.id} logged in`, user);
    return user;
}