import axios from "axios";
// @ts-ignore
import { environment } from "../../environments/environment";
import { deleteJwt, isTokenExpired, loadJwt } from "./AuthApi";

export const strapiUrl = `${environment.BACKEND_URL}:${environment.BACKEND_PORT}`;
export const strapiApiUrl = `${strapiUrl}/api`;


export const createNewAxios = () => {
    return axios.create({
        baseURL: strapiApiUrl,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        timeout: 0,
    });
}

const apiClient = createNewAxios();

apiClient.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        console.error("#error response", error.response, error);

        if (!error.response) {
            error.status = 'Network Error';
        }

        throw error;
    }
);

apiClient.interceptors.request.use((config) => {
    const token = loadJwt();

    if (token && isTokenExpired(token)) {
        deleteJwt();
    }
    if (token && token !== "undefined") {   // Token is sometimes returned as "undefined" which leads to errors
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})


axios.interceptors.request.use(function (config) {
    const token = loadJwt();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

export default apiClient;
